import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyles from "./globalStyles";

import { Navbar, Footer } from "./components";
import Home from "./pages/HomePage";
import About from "./pages/About";
import SignUp from "./pages/SignUp";
import Privacy from "./pages/Privacy";
import NotFoundPage from "./pages/NotFound";
import TermsConditions from "./pages/TermsCondition";
import ScrollToTop from "./components/ScrollToTop";
import Features from "./components/Features";
import GoogleAuthCode from "./pages/AuthCode";
import CallbackPage from "./pages/Callback";

function App() {
  return (
    <Router>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/auth" element={<GoogleAuthCode />} />
        <Route path="/callback" element={<CallbackPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
