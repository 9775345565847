import React from "react";
import Icon1 from "../../images/svg-4.svg";
import Icon2 from "../../images/svg-5.svg";
import Icon3 from "../../images/svg-6.svg";
import {
  FeaturesSection,
  FeaturesContainer,
  FeaturesH1,
  FeaturesWrapper,
  FeaturesCard,
  FeatureCardInfo,
  FeaturesIcon,
  FeaturesH2,
  FeaturesP,
} from "./styles";

const Features = () => {
  return (
    <FeaturesSection>
      <FeaturesWrapper>
        <FeaturesH1>Features</FeaturesH1>
        <FeaturesContainer>
          <FeaturesCard>
            <FeatureCardInfo>
              <FeaturesIcon src={Icon1} />
              <FeaturesH2>High Speed Download</FeaturesH2>
              <FeaturesP>
                You can download/mirror torrent, direct download link, youtube
                video at very easy and High Speed.
              </FeaturesP>
            </FeatureCardInfo>
          </FeaturesCard>
          <FeaturesCard>
            <FeatureCardInfo>
              <FeaturesIcon src={Icon2} />
              <FeaturesH2>Built-in Torrent Search</FeaturesH2>
              <FeaturesP>
                The built-in torrent search enables you to simultaneous search
                in many Popular Torrent sites.
              </FeaturesP>
            </FeatureCardInfo>
          </FeaturesCard>
          <FeaturesCard>
            <FeatureCardInfo>
              <FeaturesIcon src={Icon3} />
              <FeaturesH2>Downloads Selected Files</FeaturesH2>
              <FeaturesP>
                This Bot enables you to select files from Torrent before
                downloading using qbittorrent.
              </FeaturesP>
            </FeatureCardInfo>
          </FeaturesCard>
        </FeaturesContainer>
      </FeaturesWrapper>
    </FeaturesSection>
  );
};

export default Features;
