import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const CallbackPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = searchParams.get("code");

  sessionStorage.setItem("AuthCode", state);

  useEffect(() => {
    if (state) {
      navigate({
        pathname: "/auth",
        search: window.location.search,
      });
    } else {
      navigate("*");
    }
  }, [state, navigate]);
  return <div></div>;
};

export default CallbackPage;
