import styled from "styled-components";
import { FaBullhorn } from "react-icons/fa";

export const PrivacyContainer = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 20px;
  padding-top: 20px;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const PrivacyWrap = styled.div`
  align-items: left;
  padding: 0 0 20px;
  margin: 0 auto;
`;

export const PrivacyH1 = styled.h1`
    display: flex;
    align-items: left;
    padding: 30px 0 20px;
    margin-bottom: 24px;
    }
`;

export const HeaderIcon = styled(FaBullhorn)`
  margin-right: 0.5rem;
`;

export const PrivacyH3 = styled.h3`
    display: flex;
    align-items: left;
    margin-bottom: 24px;
    }
`;

export const PrivacyParagraph = styled.p`
  font-size: 18px;
  padding: 0 0 30px;
`;

export const PrivacyUl = styled.ul`
  margin: 0 10px 32px;
  font-size: 18px;
`;

export const PrivacyList = styled.li`
  margin: 0 30px;
`;
