import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";

import {
  MainContainer,
  Card,
  CardHeader,
  CardBody,
  CardHeaderTitle,
  AuthButton,
  textFieldStyles,
} from "./styles";
import DLoader from "../../components/DLoader";

const GoogleAuthCode = () => {
  const navigate = useNavigate();
  const [isLoaded] = useState(true);

  const AuthCode = sessionStorage.getItem("AuthCode");

  const initialState = "COPY";
  const [text, setText] = useState(AuthCode);
  const [buttonText, setButtonText] = useState("COPY");

  const initialColor = "";
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    navigate("", { replace: true });

    if (buttonText !== initialState && isActive !== initialColor) {
      setTimeout(() => {
        setButtonText(initialState);
        setIsActive(initialColor);
      }, [2000]);
    }
  }, [buttonText, isActive, navigate]);

  const changeText = (text) => setButtonText(text);

  const changeColor = () => setIsActive(true);

  return isLoaded ? (
    <MainContainer>
      <Box
        sx={{
          padding: "100px 0 100px 0",
          maxWidth: "1000px",
          margin: "auto auto",
          marginTop: "40px",
        }}
      >
        <Card>
          <CardHeader>
            <CardHeaderTitle>Google Drive Authorization Code</CardHeaderTitle>
          </CardHeader>
          <CardBody>
            <TextField
            
              sx={textFieldStyles}
              fullWidth
              variant="outlined"
              value={text}
              onChange={({ target }) => {
                setText(target.value);
              }}
            />
            <CopyToClipboard text={text}>
              <AuthButton
                style={{
                  backgroundColor: isActive ? "salmon" : "",
                  color: isActive ? "white" : "",
                }}
                onClick={() => {
                  changeText("Text copied to clipboard");
                  changeColor();
                }}
              >
                {buttonText}
              </AuthButton>
            </CopyToClipboard>
          </CardBody>
        </Card>
      </Box>
    </MainContainer>
  ) : (
    <DLoader />
  );
};

export default GoogleAuthCode;
