export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: "Lorem ipsum dolor.",
  headline: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent aliquet convallis metus, et dapibus arcu gravida quis. Curabitur vulputate a erat et tincidunt. Suspendisse potenti. Donec sed mauris a nibh.",
  buttonLabel: "Lorem ipsum",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Lorem ipsum dolor.",
  headline: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus sodales massa ut tincidunt. Mauris eget hendrerit ipsum, et molestie elit. In vehicula sollicitudin tincidunt.",
  buttonLabel: "Lorem ipsum",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTopLine: true,
  lightTextDesc: false,
  topLine: "Lorem ipsum dolor.",
  headline: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc scelerisque vehicula felis, vitae placerat lorem lobortis eu. Nunc eu dapibus mi. Nunc mattis fringilla tortor.",
  buttonLabel: "Lorem ipsum",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Lorem ipsum dolor.",
  headline: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rhoncus a ex vitae vestibulum. Pellentesque in eros porttitor, lobortis erat id, varius massa. Suspendisse auctor.",
  buttonLabel: "Lorem ipsum",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
