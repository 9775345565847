export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTopLine: true,
  lightTextDesc: true,
  topLine: "Lorem Ipsum",
  headline: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat elit a tincidunt auctor. Nam pulvinar neque consequat dictum varius. Pellentesque finibus feugiat porta.",
  buttonLabel: "Lorem Ipsum",
  links: "/",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTopLine: false,
  lightTextDesc: false,
  topLine: "Lorem Ipsum",
  headline: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat elit a tincidunt auctor. Nam pulvinar neque consequat dictum varius. Pellentesque finibus feugiat porta.",
  buttonLabel: "Learn More",
  links: "http://driveuploadbot.eu.org",
  imgStart: "start",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Lorem Ipsum",
  headline: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat elit a tincidunt auctor. Nam pulvinar neque consequat dictum varius. Pellentesque finibus feugiat porta.",
  buttonLabel: "Start Now",
  links: "http://driveuploadbot.eu.org",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Lorem Ipsum",
  headline: "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin placerat elit a tincidunt auctor. Nam pulvinar neque consequat dictum varius. Pellentesque finibus feugiat porta.",
  buttonLabel: "Sign Up Now",
  links: "http://driveuploadbot.eu.org",
  imgStart: "start",
  img: require("../../images/svg-1.svg"),
  alt: "Vault",
};
