export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Team Drive Upload Bot",
  headline: "Download Torrents and Files directly to your Google Drive",
  description:
    "Download any files from Torrents, Magnets, HTTP(S), or other sources and upload them to you Google Drive without downloading them to your device.",
  buttonLabel: "Open in Telegram",
  links: "https://telegram.me/teamdriveuploadbot",
  imgStart: "",
  img: require("../../images/svg-1.svg"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "COMPATIBILITY",
  headline: "More than hundreds of sites are supported",
  description:
    "Team Drive Upload Bot Supports Downloading files, Video, Audio from 300+ Websites like: Youtube, Instagram, Twitter, SonyLiv and many more.",
  buttonLabel: "Learn More",
  links: "http://driveuploadbot.eu.org",
  imgStart: "",
  img: require("../../images/svg-2.svg"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Lorem ipsum",
  headline: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis condimentum augue, vel pretium nulla. Duis sodales gravida massa, non porttitor dolor tincidunt vel.",
  buttonLabel: "Lorem ipsum",
  links: "http://driveuploadbot.eu.org",
  imgStart: "start",
  img: require("../../images/bot.svg"),
  alt: "Vault",
  start: "true",
};
