import React from "react";
import NotFoundImg from "../../images/page-not-found.svg";

import {
  NotFoundSection,
  NotFoundContainer,
  NotFoundRow,
  TextWrap,
  ImageWrapper,
  Image,
  TextColumn,
  TextHeading,
  TextDescription,
} from "./styles";

const NotFoundPage = () => {
  return (
    <NotFoundSection>
      <NotFoundContainer>
        <NotFoundRow>
          <ImageWrapper>
            <Image src={NotFoundImg} alt="" />
          </ImageWrapper>
          <TextColumn>
            <TextWrap>
              <TextHeading>ERROR 404</TextHeading>
              <TextDescription>
                The page you are looking for might be removed or is temporarily
                unavailable.
              </TextDescription>
            </TextWrap>
          </TextColumn>
        </NotFoundRow>
      </NotFoundContainer>
    </NotFoundSection>
  );
};

export default NotFoundPage;
