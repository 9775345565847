import React from "react";

import {
  TermsContainer,
  TermsWrap,
  TermsH1,
  TermsH3,
  TermsParagraph,
  TermsUl,
  TermsList,
  HeaderIcon,
} from "./styles";

const TermsCondition = () => {
  return (
    <TermsContainer>
      <TermsWrap>
        <TermsH1>
          <HeaderIcon />
          Terms & Conditions
        </TermsH1>
        <TermsParagraph>
          These Terms govern your access to, usage of all content, Product and
          Services available at{" "}
          <a href="driveuploadbot.eu.org">driveuploadbot.eu.org</a> website (the
          “Service”) operated by Drive Upload Bot (“us”, “we”, or “our”).
        </TermsParagraph>
        <TermsParagraph>
          Your access to our services are subject to your acceptance, without
          modification, of all of the terms and conditions contained herein and
          all other operating rules and policies published and that may be
          published from time to time by us.
        </TermsParagraph>
        <TermsParagraph>
          Please read the Agreement carefully before accessing or using our
          Services. By accessing or using any part of our Services, you agree to
          be bound by these Terms. If you do not agree to any part of the terms
          of the Agreement, then you may not access or use our Services.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Intellectual Property</TermsH3>
        <TermsParagraph>
          The Agreement does not transfer from Us to you any of Ours or third
          party intellectual property, and all right, title, and interest in and
          to such property will remain (as between the parties) solely with
          Drive Upload Bot and its licensors.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Third Party Services</TermsH3>
        <TermsParagraph>
          In using the Services, you may use third-party services, products,
          software, embeds, or applications developed by a third party (“Third
          Party Services”).
        </TermsParagraph>
        <TermsParagraph>
          If you use any Third Party Services, you understand that:
        </TermsParagraph>
        <TermsUl>
          <TermsList>
            Any use of a Third Party Service is at your own risk, and we shall
            not be responsible or liable to anyone for Third Party websites or
            Services
          </TermsList>
          <TermsList>
            You acknowledge and agree that We shall not be responsible or liable
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of any such content, goods or services
            available on or through any such web sites or services.
          </TermsList>
        </TermsUl>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Accounts</TermsH3>
        <TermsParagraph>
          Where use of any part of our Services requires an account, you agree
          to provide us with complete and accurate information when you register
          for an account.
        </TermsParagraph>
        <TermsParagraph>
          You will be solely responsible and liable for any activity that occurs
          under your account. You are responsible for keeping your account
          information up-to-date and for keeping your password secure.
        </TermsParagraph>
        <TermsParagraph>
          You are responsible for maintaining the security of your account that
          you use to access the Service. You shall not share or misuse your
          access credentials. You must notify us immediately of any unauthorized
          uses of your account or upon becoming aware of any other breach of
          security.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Termination</TermsH3>
        <TermsParagraph>
          We may terminate or suspend your access to all or any part of our
          Services at any time, with or without cause, with or without notice,
          effective immediately.
        </TermsParagraph>
        <TermsParagraph>
          If you wish to terminate the Agreement or your Team Drive Upload Bot
          account, you may simply discontinue using our Services.
        </TermsParagraph>
        <TermsParagraph>
          All provisions of the Agreement which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Disclaimer</TermsH3>
        <TermsParagraph>
          Our Services are provided “AS IS.” and “AS AVAILABLE” basis. Drive
          Upload Bot and its suppliers and licensors hereby disclaim all
          warranties of any kind, express or implied, including, without
          limitation, the warranties of merchantability, fitness for a
          particular purpose and non-infringement. Neither Drive Upload Bot, nor
          its suppliers and licensors, makes any warranty that our Services will
          be error free or that access thereto will be continuous or
          uninterrupted. You understand that you download from, or otherwise
          obtain content or services through, our Services at your own
          discretion and risk.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Jurisdiction and Applicable Law</TermsH3>
        <TermsParagraph>
          Except to the extent any applicable law provides otherwise, the
          Agreement and any access to or use of our Services will be governed by
          the laws of India. The proper venue for any disputes arising out of or
          relating to the Agreement and any access to or use of our Services
          will be the state and federal courts located in India.
        </TermsParagraph>
      </TermsWrap>
      <TermsWrap>
        <TermsH3>Changes</TermsH3>
        <TermsParagraph>
          Drive Upload Bot reserves the right, at our sole discretion, to modify
          or replace these Terms at any time.
        </TermsParagraph>
        <TermsParagraph>
          If we make changes that are material, we will let you know by posting
          on our website, or by sending you an email or other communication
          before the changes take effect. The notice will designate a reasonable
          period of time after which the new terms will take effect.
        </TermsParagraph>
        <TermsParagraph>
          If you disagree with our changes, then you should stop using our
          Services within the designated notice period, or once the changes
          become effective.
        </TermsParagraph>
        <TermsParagraph>
          Your continued use of our Services will be subject to the new terms.
        </TermsParagraph>
      </TermsWrap>
    </TermsContainer>
  );
};

export default TermsCondition;
