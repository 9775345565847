import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
`;

export const Card = styled.div`
  width: 320px;
  background-color: #fff;
  border-radius: 1rem;
  border: 2px solid #f6f6f6;
  overflow: hidden;
  margin: 0 0.5rem;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #f6f6f6;
  padding: 1rem 1.2rem;
  background-color: #fbfbfb;
`;

export const CardBody = styled.div`
  padding: 1rem 1.2rem;
`;

export const CardHeaderTitle = styled.h1`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 900;
  color: #343434;
  margin: 0;
`;

export const CardLabel = styled.p`
  font-size: 0.8rem;
  color: #343434;
  margin: 0 0 0.3rem 0;
`;

export const AuthButton = styled.button`
  width: 100%;
  background-color: #00a8e7;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  border-radius: 11px;
  padding: 10px 20px;
  border: none;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? "#4B59F7" : "#0467FB")};
  }
`;

export const SuccessText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: green;
`;

export const PlaceHolder = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #e8e8e8;
`;

export const textFieldStyles = {
  marginBottom: "20px",
};
